<template>
    <div
        class="gst-search-hotel-tab-content gst-search-hotel-modal u-height-100 d-flex flex-column">
        <div class="gst-search-hotel-modal__body u-width-100 flex-grow-1 u-overflow-auto-y pt-2">
            <LocationSearchStoreDropDown
                ref="location"
                :value="location"
                class="mb-4"
                :error-messages="_extractValidationsMessages( 'location' )"
                @input="onLocationChangeDo" />
            <DatePickerTooltipDisabledDates
                v-model="dates"
                full-width
                flat
                range
                color="primary"
                :min="minBookingStartDate"
                class="gst-search-hotel-modal__picker"
                no-title />
            <div
                class="d-flex flex-column pa-4 flex-grow-1"
                :class="[$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'pr-3']">
                <h5 class="gst-rooms-tabs-filters__guests-title">
                    {{ $t( '_common:terms.guest_plural') }}
                </h5>
                <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                    <p>{{ $t( '_common:terms.adult_plural' ) }}</p>
                    <div>
                        <NumericStep :min="1" :max="99" :value="adultsCount" :icons-attrs="iconsAttrs" @input="updateAdultsCount" />
                    </div>
                </div>
                <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                    <p>{{ $t( '_common:terms.room_plural' ) }}</p>
                    <div>
                        <NumericStep :min="1" :max="adultsCount" :value="roomsCount" :icons-attrs="iconsAttrs" @input="updateRoomsCount" />
                    </div>
                </div>
                <div class="d-flex gst-search-hotel-modal__form-input pr-sm-0 align-center justify-space-between">
                    <div class="d-flex align-start flex-row">
                        <p>
                            {{ $t( '_common:terms.children' ) }}
                        </p>
                        <span class="children-ages-label">({{ $t('labels.children') }})</span>
                    </div>
                    <div>
                        <NumericStep :min="0" :max="99" :value="childrenCount" :icons-attrs="iconsAttrs" @input="updateChildrenCount" />
                    </div>
                </div>
                <div v-if="childrenCount" class="children-ages-container d-flex flex-wrap">
                    <div v-for="(_, idx) in childrenCount" :key="idx" ref="childAgeContainer" class="col-4 px-0 py-1">
                        <div class="child-age">
                            <p>{{ $t( '_common:terms.childAge', { index: idx + 1 } ) }}</p>
                            <BaseFormSelect
                                :id="`select.childAges${idx}`"
                                v-model="childAges[idx]"
                                v-ripple
                                :options="childAgeOptions"
                                :clearable="false"
                                data-test-id="select.children"
                                class="gst-base-form-select-variant-2 u-width-100 u-height-100" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gst-search-hotel-modal__footer">
            <div class="gst-search-hotel-modal__buttons">
                <BaseButton
                    color="primary"
                    class="text-capitalize"
                    width="90%"
                    @click="submit">
                    {{ $t('_common:buttons.apply') }}
                </BaseButton>
                <BaseButton
                    text
                    color="primary"
                    class="text-capitalize"
                    width="90%"
                    @click="resetLocal( )">
                    {{ $t('_common:buttons.reset') }}
                </BaseButton>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import NumericStep from '@core/shared/components/input/NumericStep';
    import { formatQueryParam as dateUtilsFormatQueryParam } from '@tenant/app/utils/dateUtils';
    import LocationSearchStoreDropDown from '@tenant/app/components/misc/LocationSearchStoreDropDown/LocationSearchStoreDropDown';
    import DatePickerTooltipDisabledDates from '@tenant/app/components/date/DatePickerTooltipDisabledDates.vue';
    import BaseFormSelect from '@tenants/ticketmaster/app/components/inputs/BaseFormSelect.vue';
    import FilterMixin from '@tenants/ticketmaster/app/modules/hotel/features/addHotelReservation/AddHotelReservation/FilterMixin';
    import { minCheckInDate } from '@tenants/ticketmaster/app/utils/bookingUtils';
    import { addDays } from '@tenants/ticketmaster/app/utils/dateUtils';


    export default {
        name: 'SearchHotelsTabContent',
        components: {
            DatePickerTooltipDisabledDates,
            BaseButton,
            LocationSearchStoreDropDown,
            NumericStep,
            BaseFormSelect
        },
        mixins: [
            FilterMixin
        ],
        computed: {
            ...mapGetters( {
                tokenStatus: `appState/tokenStatus`
            } ),
            minBookingStartDate( ) {
                return minCheckInDate( null, this.tokenStatus );
            }
        },
        methods: {
            focus( ) {
                this.$refs.location.focus( );
            },
            submit( ) {
                if ( !this.validateForm( ) ) {
                    return;
                }
                const query = { ...this.location };

                if ( this.startDate ) {
                    query.startDate = dateUtilsFormatQueryParam( this.startDate );
                }
                if ( this.endDate ) {
                    query.endDate = dateUtilsFormatQueryParam( this.endDate );
                } else if ( this.startDate ) {
                    query.endDate = dateUtilsFormatQueryParam( addDays( this.startDate, 1 ) );
                }

                query.adultsCount = this.adultsCount;
                query.roomsCount = this.roomsCount;
                query.childrenCount = this.childrenCount,
                query.childAges = [ ...this.childAges ];

                let route = this.$router.resolve( {
                    name: 'hotels',
                    query
                } );

                window.open( route.href, '_blank' );
            },
            resetLocal( ) {
                this.reset( );
                this.adultsCount = 2;
            }
        },
        created( ) {
            this.init( );
            this.adultsCount = 2;
        }
    };
</script>
