<template>
    <v-list class="gst-search-city-menu__list">
        <template v-if="canFindMyLocation">
            <v-list-item
                key="gps"
                class="px-2"
                @click="findMyLocation">
                <v-list-item-icon class="mr-2">
                    <BaseIcon class="gst-search-city-menu__gps-icon"
                        symbol-id="icons--gps" />
                </v-list-item-icon>
                <v-list-item-title class="d-flex justify-space-between">
                    {{ $t('_common:terms.useMyCurrentLocation') }}
                    <DataLoading v-if="loadingCurrentLocation" :width="2" :size="20" class="d-flex align-self-center justify-center" />
                </v-list-item-title>
            </v-list-item>
            <v-divider />
        </template>
        <template v-if="showResults">
            <DataLoading v-if="!!loading" :width="2" class="gst-search-city-menu__loading" />
            <template v-else>
                <SearchResultNoData v-if="!cities.length">
                    {{ $t('labels.noResults') }}
                </SearchResultNoData>
                <SearchCityList :items="cities" :highlight="searchValue" @select="selectCity">
                    <template v-slot:icon>
                        <v-list-item-icon class="mr-2">
                            <BaseIcon 
                                class="gst-search-city-menu__item-icon"
                                symbol-id="icons--location2" />
                        </v-list-item-icon>
                    </template>
                </SearchCityList>
            </template>
        </template>
    </v-list>
</template>

<script>
    import { mapActions } from 'vuex';
    import DataLoading from '@core/shared/components/loading/DataLoading.vue';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import SearchResultNoData from './common/SearchResultNoData.vue';
    import SearchCityList from './SearchCityList.vue';

    export default {
        name: 'SearchCityResults',
        components: {
            DataLoading,
            BaseIcon,
            SearchResultNoData,
            SearchCityList
        },
        i18nOptions: {
            namespaces: 'shared',
            keyPrefix: 'components.search.searchCity'
        },
        props: {
            cities: {
                type: Array,
                default: ( ) => []
            },
            loading: {
                type: Number,
                default: 0
            },
            showResults: {
                type: Boolean,
                default: false
            }
        },
        data( ) {
            return {
                searchValue: '',
                loadingCurrentLocation: false,
            };
        },
        computed: {
            canFindMyLocation( ) {
                return !!navigator.geolocation;
            },
        },
        methods: {
            ...mapActions( {
                getCurrentLocation: 'user/location/getCurrentLocation',
                notificationError: 'notification/error',
            } ),
            async findMyLocation( ) {
                this.loadingCurrentLocation = true;
                const location = await this.getCurrentLocation( { showMessage: true, refresh: true } );
                if ( location && location.position ) {
                    this.selectCity( {
                        ...location.city,
                        location: {
                            current: true,
                            ...location.position
                        }
                    } );
                }
                this.loadingCurrentLocation = false;
            },
            selectCity( city ) {
                this.$emit( 'select', city );
            },
        }
    };
</script>

<style lang="scss" scoped>
    @import "@scssVariables";
    @import "@scssMixins";

    .gst-search-city-menu__gps-icon {
        ::v-deep .gst-svg-icon {
            fill: theme-color( 'new-primary' );
        }
    }
</style>