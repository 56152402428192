<template>
    <div class="gst-search-modal d-flex flex-column pa-4">
        <v-tabs
            ref="tabs"
            v-model="activeTab"
            background-color="transparent"
            grow
            color="primary"
            class="gst-search-modal__tabs d-flex justify-center"
            :style="{ flex: 0 }">
            <v-tab
                v-for="tab in tabs"
                :key="tab.id"
                :tabindex="tab.id">
                <div class="gst-tabs__icon-wrapper">
                    <BaseIcon :symbol-id="tab.icon" :class="tab.icon" />
                </div>
                <span>{{ tab.title }}</span>
            </v-tab>
        </v-tabs>
        <SearchModalEvents v-if="isEventTabActive" ref="content" :style="{ 'flex': 1, 'min-height': 0 }" class="u-overflow-auto-y" />
        <SearchHotelsTabContent v-if="isHotelTabActive" ref="content" :style="{ 'flex': 1, 'min-height': 0 }" class="ma-n2" />
    </div>
</template>

<script>
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import CloseModalOnBreakpointChange from '@core/mixins/modals/CloseModalOnBreakpointChange';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import SearchConstants from '@tenant/app/utils/constants/search';
    import SearchHotelsTabContent from '@tenant/app/modules/hotel/features/addHotelReservation/SearchHotelsTabContent.vue';
    import TabMixin from './TabsMixin';
    import SearchModalEvents from './SearchModalEvents';

    export default {
        name: 'SearchModal',
        components: {
            BaseIcon,
            SearchModalEvents,
            SearchHotelsTabContent,
        },
        mixins: [
            TabMixin,
            CloseModalOnRouteChangeMixin,
            CloseModalOnBreakpointChange
        ],
        props: {
            type: {
                type: String,
                default: SearchConstants.TYPES.EVENTS
            }
        },
        mounted( ) {
            this.$nextTick( ( ) => {
                this.$refs.content?.focus && this.$refs.content.focus( );
            } );
        },
        created( ) {
            this.activeTab = this.tabs.findIndex( item => item.id === this.type );
        }
    };
</script>

<style lang="scss">
    @import "@scssVariables";

    .gst-search-modal {
        background-color: theme-color( 'white' );

        .gst-search-modal__tabs {
            margin-bottom: 24px;

            .v-item-group {
                width: 100%;
            }

            .v-tab {
                padding: 0 4px;
                color: theme-color( 'quaternary' ) !important;
                caret-color: theme-color( 'quaternary' ) !important;
                border-bottom: 1px solid theme-color-hex( 'septenary' );

                span {
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: capitalize;
                }

                //we need a wrapper to fix https://github.com/vuetifyjs/vuetify/issues/4733
                //svg is loaded after the 1st renderer
                .gst-tabs__icon-wrapper {
                    height: 24px;
                    width: 24px;
                    margin-right: 8px;

                    svg {
                        height: 100%;
                        width: 100%;

                        .gst-svg-icon {
                            fill: theme-color( 'quaternary' );
                        }
                    }
                }
            }

            .v-tabs-slider-wrapper {
                height: 4px !important;
            }
        }

        .gst-search-modal-events {
            .gst-search-events-input {
                padding: 8px;
                border: 1px solid theme-color( 'septenary' );
                margin-bottom: 16px;

                input {
                    padding: 8px;
                }

                .icon-search {
                    .gst-svg-icon {
                        fill: theme-color( 'new-tertiary' );
                    }
                }

                .icon-clear {
                    .gst-svg-icon {
                        fill: theme-color( 'septenary' );
                    }
                }

                &.gst-search-events-input--focus {
                    border: 1px solid theme-color( 'new-primary' );

                    .icon-search {
                        .gst-svg-icon {
                            fill: theme-color( 'new-primary' );
                        }
                    }
                }
            }
        }
    }
</style>