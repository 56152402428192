<template>
    <div class="gst-search-modal-events d-flex flex-column">
        <div
            class="gst-search-events-input d-flex align-center"
            :class="{
                'gst-search-events-input--focus': isKeywordFocus
            }">
            <BaseIcon symbol-id="icons--search" class="icon-search" width="24" />
            <BaseInput
                id="search.events.modal"
                ref="keyword"
                v-model="keyword"
                :data-aria-label="$t('placeholder')"
                data-test-id="search.events"
                class="flex-grow-1"
                :placeholder="$t('placeholder')"
                @focus="onKeywordFocusDo"
                @blur="isKeywordFocus=false" />
            <BaseButton v-if="keyword" icon small text @click="clearKeyword">
                <BaseIcon symbol-id="icons--cancel" class="gst-search-icon-cancel-svg" />
            </BaseButton>
        </div>
        <div
            class="gst-search-events-input d-flex align-center"
            :class="{
                'gst-search-events-input--focus': isCityFocus
            }">
            <BaseIcon symbol-id="icons--location2" class="icon-search" />
            <BaseTextfield
                id="search.city"
                ref="city"
                :value="isCityFocus ? cityKeyword : ''"
                :placeholder="!isCityFocus ? selectValueLabel : ''"
                data-test-id="search.events"
                class="gst-search-input gst-search-city-menu__input-search flex-grow-1"
                autocomplete="off"
                data-hj-allow
                single-line
                solo
                hide-details
                flat
                dense
                @input="onInputCitySearch"
                @focus="onFocusCitySearch"
                @blur="onBlurCitySearch">
                <template v-if="!isCityFocus" slot="label">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span class="gst-search-label" v-html="$options.filters.cityHTML( selectedCity, { country: true } )"></span>
                </template>
            </BaseTextfield>
            <BaseButton v-if="cityKeyword" icon small text @click="clearCity">
                <BaseIcon symbol-id="icons--cancel" class="gst-search-icon-cancel-svg" />
            </BaseButton>
        </div>
        <SearchEventsList v-if="!isCityOpened" :event-card-bind="{contentMaxLines: 2}" class="u-overflow-auto-y" />
        <SearchCityResults
            v-if="isCityOpened"
            :cities="cities"
            :loading="citySearchLoading"
            :show-results="isCitySearching"
            @select="onCitySelectedDo" />
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import debounce from 'lodash/debounce';
    import {
        hasLocation as cityObjectUtilsHasLocation,
        hasCurrentLocation as cityObjectUtilsHasCurrentLocation
    } from '@core/utils/cityObjectUtils';
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import BaseInput from '@core/shared/components/input/BaseInput.vue';
    import BaseTextfield from '@core/shared/components/input/BaseTextfield.vue';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import SearchCityResults from '@tenant/app/components/search/SearchCityResults';
    import SearchEventsList from './SearchEvents/SearchEventsList';

    export default {
        name: 'SearchModal',
        components: {
            BaseIcon,
            BaseInput,
            BaseTextfield,
            BaseButton,
            SearchEventsList,
            SearchCityResults
        },
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.home.searchProducts.events.search'
        },
        data() {
            return {
                isKeywordFocus: false,
                isCityFocus: false,
                isCityOpened: false,
                cityKeyword: null,
                citySearchLoading: 0,
                isCitySearching: false,
                cities: []
            };
        },
        computed: {
            keyword: {
                get( ) {
                    return this.$store.state.searchEvents.filter.keyword;
                },
                set( value ) {
                    this.updateSearch( { keyword : value } );
                }
            },
            selectedCity( ) {
                return this.$store.state.searchEvents.filter.city;
            },
            selectValueLabel( ) {
                const cityLabel = this.$options.filters.city( this.selectedCity, { country: true } );
                if ( cityLabel ) {
                    return cityLabel;
                }

                if ( cityObjectUtilsHasLocation ( this.selectedCity ) ) {
                    if ( cityObjectUtilsHasCurrentLocation( this.selectedCity ) ) {
                        return this.$t( '_common:terms.myCurrentLocation' );
                    } else {
                        return this.$t( '_common:terms.location' );
                    }
                }
                return this.$t( 'placeholderCity' );
            }
        },
        watch: {
            'cityKeyword': function ( newValue ) {
                if ( !newValue ) {
                    this.onCitySelectedDo( { name: '' } );
                } else {
                    this.searchCityDebounced( );
                }
            },
            'selectedCity': function ( newValue ) {
                this.cityKeyword = newValue.name || '';
                this.isCitySearching = false;
                !this.unwatchSearchValPropAfterValueChange || this.unwatchSearchValPropAfterValueChange( );
                this.unwatchSearchValPropAfterValueChange = this.$watch( 'cityKeyword', ( newVal ) => {
                    if ( newVal ) {
                        this.isCitySearching = true;
                        this.unwatchSearchValPropAfterValueChange( );
                    }
                } );
            },
        },
        methods: {
            ...mapActions( {
                updateSearch: 'searchEvents/update',
                clearAll: 'searchEvents/clear',
                searchCities: `cities/searchList`,
            } ),
            focus( ) {
                this.$refs.keyword.focus( );
            },
            clearKeyword( ) {
                this.updateSearch( { keyword: '' } );
            },
            clearCity( ) {
                this.cityKeyword = '';
            },
            searchCityDebounced: debounce( async function ( ) {
                this.citySearchLoading = this.citySearchLoading + 1;
                this.cities = await this.searchCities( { refresh: true, search: { keyword: this.cityKeyword, limit: 5, offset: 0 } } ) || [ ];
                this.citySearchLoading = this.citySearchLoading - 1;
            }, 500 ),
            onKeywordFocusDo( ) {
                this.isKeywordFocus = true;
                this.isCityOpened = false;
            },
            onFocusCitySearch( ) {
                this.isCityFocus = true;
                this.isCityOpened = true;
            },
            onBlurCitySearch( ) {
                this.isCityFocus = false;
                // setTimeout( ( ) => {
                //     this.cityKeyword = this.selectedCity.name || '';
                //     this.isCityFocus = false;
                //     this.isCityOpened = false;
                // }, 200 );
            },
            onInputCitySearch( value ) {
                this.cityKeyword = value;
                this.isCityOpened = true;
            },
            onCitySelectedDo( value ) {
                this.isCitySearching = false;
                this.updateSearch( { city : value } );
                this.isCityOpened = false;
            }
        },
        created( ) {
            this.clearAll( );
            this.cityKeyword = this.selectedCity.name;
            this.$watch( 'cityKeyword', ( newVal ) => {
                if ( newVal && newVal !== this.selectedCity.name ) {
                    this.isCitySearching = true;
                    this.searchCityDebounced( );
                }
            } );
        }
    };
</script>

<style lang="scss" scoped>

.gst-search-city-menu__input-search {
    ::v-deep .v-input__control {
        min-height: 0 !important;
    }

    ::v-deep .v-input__slot {
        padding: 0 !important;
    }
}
</style>